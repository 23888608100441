import React from 'react';
import { withRouter } from 'react-router';
import Header from '../Components/Header';
import Spinner from '../Components/Spinner';
import Background from '../images/BackgroundImage.jpg';
import '../css/NoSuccessPage.css';

var sectionStyle = {
    width: "100vw",
    height: "85vh",
    "padding-top": "100px",
    backgroundImage: "url(" + Background + ")"
  };

function SuccessPage({ history }) {

    const body = () => {
        return (
            <React.Fragment>
                <div className="o-root">
                    
                    <Header />
                    <main className="o-main" >
                        <section className="o-content-section" style={ sectionStyle }>
                            <div className="o-page-wrap">
                                <form
                                    id="main-form"
                                    className="js-floating-label u-p"
                                    action="javascript:void(0);"
                                >
                                    <fieldset className="o-fieldset">
                                        <div className="o-fieldset__row">
                                            <div className="o-layout o-layout--center">
                                                <div className="o-layout__item u-4/6 u-text-center">
                                                    <i className="c-icon c-icon--[semantic-checkmark]" />
                                                </div>
                                                <div className="o-layout__item u-4/6 u-text-center">
                                                    <p>
                                                        <strong>
                                                        {' '}
                                                        Ya tienes una solicitud en curso.{' '}
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        {' '}
                                                    </p>
                                                    <p>
                                                        {' '}Tu Solicitud está siendo procesado por tu empresa.{' '}
                                                    </p>
                                                    <p>
                                                        {' '}Cuando tu solicitud sea aprobada recibirás un correo con las instrucciones para entrar en FS Cars.{' '}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </section>
                        <div class="c-footer">
                            <div class="c-footer__module  c-footer__module--footnotes">
                                <div class="o-page-wrap o-page-wrap--xsmall">
                                    <div class="c-footer-footnotes">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </React.Fragment>
            
        );
    };

    const renderBody = () => {
        if (history.location.state && history.location.state.wrapper) {
            return (
                <fieldset className="o-fieldset">
                    <div className="o-fieldset__row">
                        {body()}
                    </div>
                </fieldset>
            );
        }
        return body();
    };

    return renderBody();
}
export default withRouter(SuccessPage);
