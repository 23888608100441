import { stringIsNullOrEmpty } from '../utils/validations';

export default {
    validations: {
        NIFNIE: 'NIF/NIE incorrecto',
        CORREO: 'Correo incorrecto',
        CATEGORIA: 'Campo obligatorio',
        NOMBRE: 'Nombre incorrecto o vacío',
        APELLIDO: 'Apellido incorrecto o vacío',
    },
};

export function validateInput(elementId, setError, error, customValidation, userType) {
    if (customValidation !== null && customValidation !== undefined) {
        if (customValidation(document.getElementById(elementId).value, userType)) {
            setError(null);
            return true;
        } else {
            setError(error);
            return false;
        }
    } else if (stringIsNullOrEmpty(document.getElementById(elementId).value)) {
        setError(error);
        return false;
    } else {
        setError(null);
        return true;
    }
}
