import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import FormPage from './Pages/FormPage';
import SuccessPage from './Pages/SuccessPage';
import Globals from './Globals';
import NoSuccessPage from './Pages/NoSuccessPage';
import ErrorPage from './Pages/ErrorPage';

function App() {
    return (
        <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact
                            path={Globals.APPLICATION_PATH.ROOT}
                            component={FormPage}
                        />
                        <Route
                            exact
                            path={Globals.APPLICATION_PATH.COMPLETED}
                            component={SuccessPage}
                        />
                        <Route
                            exact
                            path={Globals.APPLICATION_PATH.NOCOMPLETED}
                            component={NoSuccessPage}
                        />
                        <Route
                            exact
                            path={Globals.APPLICATION_PATH.ERROR}
                            component={ErrorPage}
                        />
                    </Switch>
                </BrowserRouter>
        </div>
    );
}

export default App;
