import AppService from './AppService';

export async function GetCategories() {
    var queryString = process.env.REACT_APP_URL_GET_CATEGORY_LIST;
    var requestBody = process.env.REACT_APP_COMPANY_ID;

        var headers = {};
        headers[process.env.REACT_APP_AWS_APIKEY_NAME] = process.env.REACT_APP_AWS_APIKEY_VALUE;
        var config = {
            headers: headers,
        };

    return AppService.post(queryString, requestBody, config)
        .then(response => {
            if (response && response.data && response.status === 200) {
                if (response.data.result) {
                    return JSON.stringify(response.data.result);
                }
            }
        })
        .catch(function (error) {});
}

export async function GetCompaniesCarpolicy() {
    var queryString = process.env.REACT_APP_URL_GET_COMPANYCARPOLICY_LIST;
    var requestBody = process.env.REACT_APP_COMPANY_ID;

        var headers = {};
        headers[process.env.REACT_APP_AWS_APIKEY_NAME] = process.env.REACT_APP_AWS_APIKEY_VALUE;
        var config = {
            headers: headers,
        };

    return AppService.post(queryString, requestBody, config)
        .then(response => {
            if (response && response.data && response.status === 200) {
                if (response.data.result) {
                    return JSON.stringify(response.data.result);
                }
            }
        })
        .catch(function (error) {});
}

