import { isValid, isNIF, isNIE, normalize } from 'better-dni';

export function ValidateSpanishID(str, userType) {
    //Clean dni string removing spaces, dashes, etc
    str = normalize(str);

    if (isValid(str)) return true; //DNI Validation
    if (str.match(/^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/)) return true; //CIF Validation
    if (isNIF(str)) return true;
    if (isNIE(str)) return true;

    return false;
}

export function ValidateSpanishIDNoCif(str, userType) {
    //Clean dni string removing spaces, dashes, etc
    str = normalize(str);

    if (isValid(str)) return true; //DNI Validation
    if (isNIF(str)) return true;
    if (isNIE(str)) return true;

    return false;
}

export function validateEmail(email, userType) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function stringIsNullOrEmpty(value, userType) {
    if (value == null) return true;
    if (value === undefined) return true;
    if (value === '') return true;

    return false;
}

export function onlyLetters(event, userType) {
    if (
        (event.charCode >= 65 && event.charCode <= 90) ||
        (event.charCode >= 97 && event.charCode <= 122)
    ) {
        return true;
    }
    return false;
}
