/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import NewUserForm from '../Components/NewUserForm';
import '../css/formPage.css';
import Spinner from '../Components/Spinner';
import SuccessPage from './SuccessPage';
import Background from '../images/BackgroundImage.jpg';

const logo = require('../images/logo.svg'); 

var sectionStyle = {
    width: "100vw",
    backgroundImage: "url(" + Background + ")"
  };

function FormPage() {
    const [showNewUserForm, setNewUserForm] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [potentialClientData, setPotentialClientData] = useState([]);

    return (
        <React.Fragment>
            <div className="o-root">
                
                <Header />
                <main className="o-main" >
                    <Spinner isLoading={isLoading} />
                    <section className="o-content-section" style={ sectionStyle }>
                        <div className="o-page-wrap">
                            <form
                                id="main-form"
                                className="js-floating-label u-p"
                                action="javascript:void(0);"
                            >
                                <fieldset className="o-fieldset">
                                    <legend className="c-form-heading">
                                        <strong className="c-form-heading__title">
                                            <span className="c-form-heading__title-text">
                                                Solicitud de acceso a FSCars
                                            </span>
                                        </strong>
                                    </legend>
                                    <div className="o-fieldset__row">
                                        <NewUserForm
                                            potentialClientData={
                                                potentialClientData
                                            }
                                            setIsLoading={setIsLoading}
                                            setPotentialClientData={
                                                setPotentialClientData
                                            }
                                        />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </section>
                    <div class="c-footer">
                        <div class="c-footer__module  c-footer__module--footnotes">
                            <div class="o-page-wrap o-page-wrap--xsmall">
                                <div class="c-footer-footnotes">
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
}

export default FormPage;
