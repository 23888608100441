import React from 'react';

const Spinner = ({ isLoading }) => {
    return (
        <div
            className="c-spinner  c-spinner--full-page"
            aria-live="polite"
            aria-busy={isLoading}
        >
            <div className="c-spinner__loader">
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
                <span className="c-spinner__element"></span>
            </div>
        </div>
    );
};

export default Spinner;
