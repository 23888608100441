import React from 'react';

const Modal = ({
    hideModal,
    onToggle,
    titleText,
    buttonText,
    children,
}) => {
    return (
        <div className="c-modal  " aria-hidden={hideModal} id="demo-modal">
            <div
                className="c-modal__overlay"
                tabindex="-1"
            ></div>

            <div
                className="c-modal__wrapper"
                role="dialog"
                aria-labelledby="foo-bar-baz"
            >
                <header className="c-modal__header">
                    {titleText ? (
                        <div className="c-modal__title__wrapper">
                            <h1 className="c-modal__title" id="foo-bar-baz">
                                {titleText}
                            </h1>
                        </div>
                    ) : null}
                    <button
                        className="c-modal__close-btn"
                        type="button"
                        aria-label="Close this dialog window."
                        onClick={onToggle}
                    ></button>
                </header>

                <div className="c-modal__content u-text-left" role="document">
                    {children}
                </div>
                <div className="c-modal__footer">
                    <div className="c-modal__actions">
                        <div className="c-modal__actions__item">
                            <button
                                className="c-btn js-modal-autofocus"
                                aria-label="Close this dialog window."
                                onClick={onToggle}
                            >
                                <span className="c-btn__text">{ buttonText ? buttonText : "Cerrar" }</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
