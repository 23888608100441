import axios from 'axios';

const AppService = {
    get: function Get(url, config) {
        return axios
            .get(url, config)
            .then(response => {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    },

    post: function Post(url, data, config) {
        return axios
            .post(url, data, config)
            .then(response => {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    },
};

export default AppService;
