import AppService from './AppService';
import { config } from './Config';

export async function LoginAWS() {
    let token = window.sessionStorage.getItem('cookieJWT');
    if (token) {
        let tokenParsed = JSON.parse(token);
        if (new Date(tokenParsed.AccessTokenExpiration) > new Date()) {
            return tokenParsed.AccessToken;
        }
    }
    /* userType = A (Autónomo/Empresa), E (Exportador) */
    var queryString = process.env.REACT_APP_URL_LOGIN_AWS;
    var requestBody = {
        Body: JSON.stringify({ UserId: process.env.REACT_APP_JWT_USERID }),
    };

    var count = 5;

    return AppService.post(queryString, requestBody, config)
        .then(response => {
            //401 volver a pedir un token, aguanta 30 min
            if (response && response.data.statusCode === 200) {
                var body = JSON.parse(response.data.body);
                window.sessionStorage.setItem('cookieJWT', body.Result);
                return JSON.parse(body.Result).AccessToken;
            } else {
                //Volvemos a pedir el token para refrescarlo
                count--;
                if (count > 0) LoginAWS();
            }
        })
        .catch(function (error) {});
}


export async function InsertFlotaClient(data) {
    var queryString = process.env.REACT_APP_URL_INSERT_CLIENT_FSCARS;
    var requestBody = data;

    var headers = {};
        headers[process.env.REACT_APP_AWS_APIKEY_NAME] = process.env.REACT_APP_AWS_APIKEY_VALUE;
        var config = {
            headers: headers,
        };
    return AppService.post(queryString, requestBody, config)
        .then(response => {
        if (response && response.data && response.status === 200) {
                    return response.data.result;
                }
                else
                {
                    return 0;
                }
            })
        .catch(function (error) {});
}

const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
