import React from 'react';

const Header = () => {
    return (
        <header id="header-div-container" className="c-header__wrapper">
            <div className="c-header c-header--static">
                <div className="c-header__content-wrapper">
                    <div className="c-header__logo">
                        <a
                            className="c-logo  c-logo--has-image"
                            href="/"
                            title="INSERT YOUR OWN TEXT THAT DESCRIBES THE LINK"
                            aria-label="Logo"
                        >
                            <picture>
                                <source
                                    srcSet="https://cdn.bronson.vwfs.tools/bluelabel/v/7.24.0/img/logo-small-screen.svg"
                                    media="(max-width: 720px)"
                                />
                                <img
                                    src="https://cdn.bronson.vwfs.tools/bluelabel/v/7.24.0/img/logo.svg"
                                    alt="INSERT YOUR OWN ALTERNATIVE TEXT THAT DESCRIBES THE LOGO"
                                    className="c-logo__image"
                                />
                            </picture>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;